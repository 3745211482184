"use client";
// Logger Initialization
import "./loggers";
//

import { ErrorPage, fonts } from "@hkexpressairwayslimited/ui";
import ClientLayout from "[lang]/ClientLayout";
import { i18n } from "modules/common/i18n/init";
import { useTransContent } from "modules/common/trans-content/transContent";

export const revalidate = 300;

export const dynamic = "force-static";

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const { t, tPlain } = useTransContent();

  function tryAgin() {
    reset();
  }

  return (
    <html lang={i18n.language} className={fonts} suppressHydrationWarning>
      <body>
        <ClientLayout params={{ lang: i18n.language }}>
          <ErrorPage
            title={t("web.page.somethingWentWrong.title")}
            text={t("web.page.somethingWentWrong.text")}
            desc={tPlain("web.page.somethingWentWrong.desc")}
            auto={false}
            onCountdownEnd={tryAgin}
          />
        </ClientLayout>
      </body>
    </html>
  );
}
